<template>
    <section class="video-gallery-section  blog-posts-wrap">

        <div class="container">
            <div class="blog-posts-header">
                <h5 class="section-title">Watch & Buy</h5>
            </div>
            <div class="row" style="margin-top:5%;">
                <div class="col-md-3" v-for="(video, index) in videos" :key="index">
                    <div class="video-item" @click="openModal(video.url)">
                        <img :src="video.thumbnail_url" :alt="'Video ' + (index + 1)">
                        <div class="play-button">&#9654;</div>
                    </div>
                    <div classs="product-card-bottom">
                        <div class="product-price" v-html="video.product.formatted_price"></div>
                            <button class="btn btn-primary btn-add-to-cart" @click="addToCart"
                                style="width:100%;line-height:18px !important;" tabindex="0"><i
                                    class="las la-cart-arrow-down"></i>
                                Add to Cart </button>
                        </div>
                </div>

            </div>
        </div>

        <!-- Modal -->
        <div v-if="isModalVisible" tabindex="-1" class="modal" style="display: block;" @click="closeModal">
            <div class="modal-dialog modal-dialog-centered">
                <div class="">
                    <div class="modal-body">
                        <div class="newsletter-inner">
                            <video ref="videoPlayer" :src="modalVideoUrl" class="video-player" @click="togglePlayPause"
                                preload="metadata">
                                <!-- Custom Controls -->
                                <div v-if="isControlsVisible" class="custom-controls">
                                    <button @click="togglePlayPause" class="play-pause-button">
                                        {{ isPlaying ? 'Pause' : 'Play' }}
                                    </button>
                                </div>
                            </video>
                            <button type="button" class="close" @click="closeModal">
                                <i class="las la-times"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import ProductCardMixin from "../../mixins/ProductCardMixin";
export default {
     mixins: [ProductCardMixin],
    data() {
        return {
            isModalVisible: false,
            modalVideoUrl: '',
            isControlsVisible: true,
            isPlaying: false,
            // videoThumbnails: [
            //     'http://127.0.0.1:8000/storage/media/XcZ6F7oVcEaywFyjp8CkkvzWvvUOjLAUkdyddpAT.png',
            //     'http://127.0.0.1:8000/storage/media/r5QDmwZPph60vluNsYJjRrxHH8OFII7PlPHbVJFL.png',
            //     'http://127.0.0.1:8000/storage/media/IkcQuuSNYR4DT4Fn4YvIGvmsbPMIVNYFyLZ27S5T.png',
            //     'http://127.0.0.1:8000/storage/media/r5QDmwZPph60vluNsYJjRrxHH8OFII7PlPHbVJFL.png',
            //     'http://127.0.0.1:8000/storage/media/XcZ6F7oVcEaywFyjp8CkkvzWvvUOjLAUkdyddpAT.png',
            //     'http://127.0.0.1:8000/storage/media/IkcQuuSNYR4DT4Fn4YvIGvmsbPMIVNYFyLZ27S5T.png'
            // ],
            videos: [
                // 'http://127.0.0.1:8000/storage/videos/1.mp4',
                // 'http://127.0.0.1:8000/storage/videos/2.mp4',
                // 'http://127.0.0.1:8000/storage/videos/3.mp4',
                // 'http://127.0.0.1:8000/storage/videos/1.mp4',
                // 'http://127.0.0.1:8000/storage/videos/2.mp4',
                // 'http://127.0.0.1:8000/storage/videos/3.mp4'
            ],
            touchStartY: 0,
            touchEndY: 0
        };
    },
    mounted() {
        this.fetchVideos();
    },
    methods: {
        async fetchVideos() {
            try {
                const response = await axios.get(route('storefront.video_banners.index'));
                const data = response.data;
                this.videos=data;
// console.log(data);
                // this.videos = data.map(item => item.url);
                // this.videoThumbnails = data.map(item => item.thumbnail_url);

                // console.log(this.videos);
                // console.log(this.videoThumbnails);
            } catch (error) {
                console.error('Error fetching videos:', error);
            }
        },
        openModal(videoUrl) {
            this.modalVideoUrl = videoUrl;
            this.isModalVisible = true;
            this.$nextTick(() => {
                this.$refs.videoPlayer.play();
                this.isPlaying = true;

                window.addEventListener('scroll', this.closeModal);
                window.addEventListener('touchstart', this.handleTouchStart);
                window.addEventListener('touchend', this.handleTouchEnd);
            });
        },
        closeModal() {
            if (this.isModalVisible) {
                this.isModalVisible = false;
                this.modalVideoUrl = '';
                this.$nextTick(() => {
                    this.$refs.videoPlayer.pause();
                    this.isPlaying = false;

                    window.removeEventListener('scroll', this.closeModal);
                    window.removeEventListener('touchstart', this.handleTouchStart);
                    window.removeEventListener('touchend', this.handleTouchEnd);
                });
            }
        },
        togglePlayPause() {
            const video = this.$refs.videoPlayer;
            if (video.paused) {
                video.play();
                this.isPlaying = true;
            } else {
                video.pause();
                this.isPlaying = false;
            }
        },
        handleTouchStart(event) {
            this.touchStartY = event.touches[0].clientY;
        },
        handleTouchEnd(event) {
            this.touchEndY = event.changedTouches[0].clientY;
            if (this.touchEndY - this.touchStartY > 50) {
                // Swipe down detected
                this.closeModal();
            } else if (this.touchStartY - this.touchEndY > 50) {
                // Swipe up detected
                this.closeModal();
            }
        }
    }
};
</script>

<style scoped>
.video-gallery-section {
    position: relative;
}

.video-item {
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
    height: 250px;
}

.product-price {
    justify-content: space-between;
}

.video-item img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    transition: opacity 0.3s ease;
}

.video-item:hover img {
    opacity: 0.7;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.6);
    color: white;
    border-radius: 50%;
    padding: 8px;
    cursor: pointer;
    font-size: 24px;
    display: block;
}

.video-item:hover .play-button {
    display: block;
}

.modal {
    display: flex;
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
    align-items: center;
    justify-content: center;
}

.modal-dialog-centered {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.modal-content {
    background: white;
    border-radius: 8px;
    overflow: hidden;
    width: 80%;
    height: 80%;
}

.modal-body {
    position: relative;
    height: 100%;
}

.video-player {
    width: 100%;
    height: 100%;
}

.custom-controls {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
}

.play-pause-button {
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
}

.play-pause-button:hover {
    background-color: rgba(0, 0, 0, 0.9);
}

.close {
    position: absolute;
    top: 10px;
    right: 25px;
    color: white;
    font-size: 35px;
    font-weight: bold;
    cursor: pointer;
}

.close:hover,
.close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}
</style>
