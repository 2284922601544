<template>
    <section class="landscape-tab-products-wrap">
        <div class="container">
            <div class="tab-products-header">
                <h5 class="section-title">{{ data.title }}</h5>

                <div class="tab-products-header-overflow row">
                    <ul class="tabs">
                        <li
                            v-for="(tab, index) in tabs"
                            :key="index"
                            :class="classes(tab)"
                            @click="change(tab)"
                        >
                            {{ tab.label }}
                        </li>
                    </ul>

                    <hr>
                </div>
            </div>

            <div class="tab-content">
                <div class="landscape-right-tab-products">
                    <ProductCard
                        v-for="product in products"
                        :key="product.id"
                        :product="product"
                    />
                </div>
            </div>
        </div>

        <dynamic-tab
            v-for="(tabLabel, index) in data.tabs"
            :key="index"
            :label="tabLabel"
            :url="
                route('storefront.tab_products.index', {
                    sectionNumber: 2,
                    tabNumber: index + 1,
                })
            "
        >
        </dynamic-tab>
    </section>
</template>
<style scoped>
    .landscape-right-tab-products {
        display: flex;
        flex-wrap: wrap;
        margin-right: -20px; /* Negative margin to balance the gap */
        /* gap: 20px; */
    }

    .landscape-right-tab-products > * {
        flex: 0 0 calc(25% - 20px); /* Ensure each card takes up 25% minus the gap */
        max-width: calc(25% - 20px); /* Max width for the cards */
    }

    @media (max-width: 992px) {
        .landscape-right-tab-products > * {
            flex: 0 0 calc(33.33% - 20px); /* 3 cards per row */
            max-width: calc(33.33% - 20px);
        }
    }

    @media (max-width: 768px) {
        .landscape-right-tab-products > * {
            flex: 0 0 calc(50% - 20px); /* 2 cards per row */
            max-width: calc(50% - 20px);
        }
    }

    @media (max-width: 576px) {
        .landscape-right-tab-products > * {
            flex: 0 0 100%; /* Full width for mobile */
            max-width: 100%;
        }
    }
</style>



<script>
import ProductCard from "../ProductCard.vue";
import DynamicTabsMixin from "../../mixins/DynamicTabsMixin";

export default {
    components: { ProductCard },

    mixins: [DynamicTabsMixin],

    props: ["data"],

    methods: {
        selector() {
            return $(".landscape-right-tab-products");
        },

        // slickOptions() {
        //     return {
        //         rows: 0,
        //         dots: true,
        //         arrows: false,
        //         infinite: true,
        //         slidesToShow: 6,
        //         slidesToScroll: 6,
        //         rtl: window.FleetCart.rtl,
        //         responsive: [
        //             {
        //                 breakpoint: 1761,
        //                 settings: {
        //                     slidesToShow: 4,
        //                     slidesToScroll: 4,
        //                 },
        //             },
        //             {
        //                 breakpoint: 1301,
        //                 settings: {
        //                     slidesToShow: 4,
        //                     slidesToScroll: 4,
        //                 },
        //             },
        //             {
        //                 breakpoint: 1051,
        //                 settings: {
        //                     slidesToShow: 3,
        //                     slidesToScroll: 3,
        //                 },
        //             },
        //             {
        //                 breakpoint: 992,
        //                 settings: {
        //                     slidesToShow: 4,
        //                     slidesToScroll: 4,
        //                 },
        //             },
        //             {
        //                 breakpoint: 881,
        //                 settings: {
        //                     slidesToShow: 3,
        //                     slidesToScroll: 3,
        //                 },
        //             },
        //             {
        //                 breakpoint: 768,
        //                 settings: {
        //                     slidesToShow: 3,
        //                     slidesToScroll: 3,
        //                 },
        //             },
        //             {
        //                 breakpoint: 641,
        //                 settings: {
        //                     slidesToShow: 2,
        //                     slidesToScroll: 2,
        //                 },
        //             },
        //         ],
        //     };
        // },
    },
};
</script>
