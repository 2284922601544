<template>
    <section class="featured-categories-wrap">
        <h1>rfffffff</h1>
        <div class="container">
            <div class="featured-categories-header">
                <div class="featured-categories-text">
                    <h2 class="title">{{ title }}</h2>
                    <span class="excerpt">{{ subtitle }}</span>
                </div>

                <ul class="tabs featured-categories-tabs">
                    <li
                        v-for="(category, index) in categories"
                        :key="index"
                        :class="classes(category)"
                        @click="change(category)"
                    >
                        <div class="featured-category-image">
                            <img
                                :src="category.logo"
                                alt="Category logo"
                            />
                        </div>

                        <span class="featured-category-name">{{ category.name }}</span>
                    </li>
                </ul>
            </div>

            <div class="tab-content">
                <div class="featured-category-products">
                    <ProductCard
                        v-for="product in selectedProducts"
                        :key="product.id"
                        :product="product"
                    />
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { slickPrevArrow, slickNextArrow } from "../../functions";
import ProductCard from "../ProductCard.vue";
import axios from 'axios';  // Import axios to make API requests

export default {
    components: { ProductCard },

    data() {
        return {
            title: 'Featured Categories',
            subtitle: 'Explore our featured categories',
            categories: [],  // To store the fetched categories
            productsByCategory: {},  // To store products for each category
            selectedProducts: [],  // To store products for the selected category
            activeCategoryId: null,  // To track the currently active category
        };
    },

    mounted() {
        // Fetch the categories and products when the component is mounted
        this.fetchCategoriesAndProducts();
    },

    methods: {
        // Method to fetch categories and products from the backend
        fetchCategoriesAndProducts() {
            axios.get('/storefront/product-all')
                .then((response) => {
                    alert(response);
                    // Store categories and products from the response
                    this.categories = response.data.categories;
                    this.productsByCategory = response.data.products;

                    // Set the first category as active by default
                    if (this.categories.length > 0) {
                        this.change(this.categories[0]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching categories and products:', error);
                });
        },

        // Method to handle tab change (category selection)
        change(category) {
            this.activeCategoryId = category.id;
            this.selectedProducts = this.productsByCategory[category.id] || [];
        },

        // Optionally add class logic if needed
        classes(category) {
            return {
                active: this.activeCategoryId === category.id
            };
        },

        selector() {
            return $(".featured-category-products");
        },

        slickOptions() {
            return {
                rows: 0,
                dots: true,
                arrows: false,
                infinite: true,
                slidesToShow: 6,
                slidesToScroll: 6,
                rtl: window.FleetCart.rtl,
                prevArrow: slickPrevArrow(),
                nextArrow: slickNextArrow(),
                responsive: [
                    {
                        breakpoint: 1761,
                        settings: {
                            slidesToShow: 5,
                            slidesToScroll: 5,
                        },
                    },
                    {
                        breakpoint: 1301,
                        settings: {
                            slidesToShow: 4,
                            slidesToScroll: 4,
                        },
                    },
                    {
                        breakpoint: 768,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        },
                    },
                    {
                        breakpoint: 577,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        },
                    },
                ],
            };
        },
    },
};
</script>
