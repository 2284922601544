<template>
    <div>
      <!-- <h3>Select a Location</h3> -->
      <div id="map"></div>
    </div>
  </template>

  <script>
  export default {
    name: 'LocationMap',
    data() {
      return {
        map: null,
        marker: null,
        leafletLoaded: false,
      };
    },
    mounted() {
      this.loadLeaflet().then(() => {
        this.initializeMap();
      });
    },
    methods: {
      loadLeaflet() {
        return new Promise((resolve, reject) => {
          if (this.leafletLoaded) {
            resolve();
            return;
          }

          const script = document.createElement('script');
          script.src = 'https://unpkg.com/leaflet/dist/leaflet.js';
          script.onload = () => {
            this.leafletLoaded = true;
            resolve();
          };
          script.onerror = reject;

          document.head.appendChild(script);
        });
      },
      initializeMap() {
        this.map = L.map('map').setView([29.3759, 47.9774], 13);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 19,
          attribution: '© OpenStreetMap'
        }).addTo(this.map);

        this.map.on('click', this.onMapClick);
      },
      async onMapClick(e) {
        if (this.marker) {
          this.map.removeLayer(this.marker);
        }
        this.marker = L.marker(e.latlng).addTo(this.map);

        try {
          const response = await fetch(`https://nominatim.openstreetmap.org/reverse?lat=${e.latlng.lat}&lon=${e.latlng.lng}&format=json`);
          const data = await response.json();

          this.$emit('location-selected', data.address);
          $('#area').val(data.address.suburb);
          $('#billing-building').val(data.address.house_number);
          $('#billing-block').val(data.address.neighbourhood);
          $('#billing-street').val(data.address.road);
          $('#billing-governorate').val(data.address.state);
        } catch (err) {
          console.error(err);
        }
      }
    }
  };
  </script>

  <style scoped>
  #map {
    height: 300px;
    width: 100%;
    position: sticky !important;
  }
  </style>
