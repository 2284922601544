<template>
    <section class="banner-wrap one-column-banner">
        <div class="product-card-bottom">
            <div class="product-price" v-html="video.products.formatted_price"></div>
            <button @click="addToCart(video)" class="btn btn-primary btn-add-to-cart"
                style="width:100%;line-height:18px !important;" tabindex="0"><i class="las la-cart-arrow-down"></i>
                Add to Cart </button>
        </div>
    </section>
</template>

<script>
import ProductCardMixin from "../../mixins/ProductCardMixin";
import store from "../../store";
export default {
    mixins: [ProductCardMixin],
    props: ["video"],

    methods: {
        addToCart(product) {
            // console.log(product);

            axios.post(
                route("cart.items.store", {
                    product_id: product.product_id,
                    qty: 1,
                })
            )
                .then((response) => {
                    // console.log(response);

                    store.updateCart(response.data);

                    $(".header-cart").trigger("click");
                })
                .catch((error) => {
                    console.log(error);

                    this.$notify(error.response.data.message);
                });
        },
    }
};
</script>
