import Errors from "../../../Errors";

export default {
    props: ["initialAddresses", "initialDefaultAddress", "countries",],

    data() {
        return {
            addresses: this.initialAddresses,
            defaultAddress: this.initialDefaultAddress,
            // form: { state: "" },
            form:{
                street_no: '',
                  area: '',

                billing: {
                    country: 'KW',
                    state: '', // Governorate
                    area: '',

                },
                shipping: {
                    country: 'KW',
                },
                billingAddressId: null,
                shippingAddressId: null,
                newBillingAddress: false,
                newShippingAddress: false,
                ship_to_a_different_address: false,
            },
            states: {
                billing: {},
                // shipping: {},
            },
            districts: {
                billing: {},
                // shipping: {},
            },
            controller: null,
            placingOrder: false,
            errors: new Errors(),
                    // errors: new Errors(),
            formOpen: false,
            editing: false,
            loading: false,
        };
    },

    computed: {
        firstCountry() {
            return Object.keys(this.countries)[0];
        },
        hasBillingDistricts() {
            return Object.keys(this.districts.billing).length !== 0;
        },
        hasBillingStates() {
            return Object.keys(this.states.billing).length !== 0;
        },
        hasAddress() {
            return Object.keys(this.addresses).length !== 0;
        },

        hasNoStates() {
            return Object.keys(this.states).length === 0;
        },
    },
    watch: {
        "form.billing.state": function (newState) {
            if (newState) {
                this.fetchDistricts(newState, (response) => {
                    this.$set(this.districts, "billing", response.data);
                });
            }
        },
        "form.billing.area": function (newArea) {
            if (newArea) {
                this.addTaxes();
            }
        },
    },

    created() {
        const savedFormData = JSON.parse(sessionStorage.getItem('formData') || '{}');
        this.form = { ...this.form, ...savedFormData };
        sessionStorage.removeItem('formData');

        this.changeCountry(this.firstCountry);
    },

    methods: {
        // changeBillingState(state) {
        //     this.$set(this.form.billing, "state", state);
        //     this.fetchDistricts(state, (response) => {
        //         this.$set(this.districts, "billing", response.data);
        //     });
        // },
        changeBillingState(state) {
            this.$set(this.form.billing, "state", state);

            if (state === "") {
                this.form.billing.area = "";
                this.districts.billing = {};
                return;
            }

            this.fetchDistricts(state, (response) => {
                this.$set(this.districts, "billing", response.data);
                this.$set(this.form.billing, "area", "");
            });
        },

        changeBillingArea(area) {
            this.$set(this.form.billing, "area", area);
        },
        fetchDistricts(state, callback) {
            axios
                .get(route("countries.districts.index", { code: state }))
                .then(callback);
        },
        addTaxes() {
            this.loadingOrderSummary = true;

            axios.post(route("cart.taxes.store"), this.form)
                .then(response => {
                    store.updateCart(response.data);
                })
                .catch(error => {
                    this.$notify(error.response.data.message);
                })
                .finally(() => {
                    this.loadingOrderSummary = false;
                });
        },
        changeDefaultAddress(address) {
            if (this.defaultAddress.address_id === address.id) return;

            this.$set(this.defaultAddress, "address_id", address.id);

            axios
                .post(route("account.addresses.change_default"), {
                    address_id: address.id,
                })
                .then((response) => {
                    this.$notify(response.data);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                });
                console.log("Fetched states:", this.states);

        },

        changeCountry(country) {
            this.form.country = country;
            this.form.state = "";

            this.fetchStates(country);
        },

        async fetchStates(country) {
            const response = await axios.get(
                route("countries.states.index", { code: country })
            );
            console.log("Fetched states:", this.states);

            this.$set(this, "states", response.data);
        },
        edit(address) {
            this.formOpen = true;
            this.editing = true;
            this.resetForm();

            // Merge the address into the form
            this.form = {
                ...this.form,
                ...address,
                 street_no: address.address_2 ,
                 area: address.address_1,
                billing: {
                    ...this.form.billing,
                    ...address.billing,
                    area: address.address_1,
                    street_no: address.address_2
                },
                shipping: {
                    ...this.form.shipping,
                    ...address.shipping
                }
            };

            console.log("uuuu",this.form);
            this.fetchStates(address.country);
        },

    //     edit(address) {
    //         this.formOpen = true;
    //         this.editing = true;
    //         // this.form = address;
    //           this.resetForm();

    // // Merge the address into the form
    // this.form = {
    //     ...this.form,
    //     ...address,
    //     billing: {
    //         // ...this.form.billing,
    //         // ...address.billing
    //     },
    //     shipping: {
    //         // ...this.form.shipping,
    //         // ...address.shipping
    //     }
    // };

    //         this.fetchStates(address.country);
    //     },

        remove(address) {
            if (
                !confirm(this.$trans("storefront::account.addresses.confirm"))
            ) {
                return;
            }

            axios
                .delete(route("account.addresses.destroy", address.id))
                .then((response) => {
                    this.$delete(this.addresses, address.id);
                    this.$notify(response.data.message);
                })
                .catch((error) => {
                    this.$notify(error.response.data.message);
                });
        },

        cancel() {
            this.editing = false;
            this.formOpen = false;

            this.errors.reset();
            this.resetForm();
        },

        save() {
            console.log("Form data:", this.form);
            this.loading = true;

            if (this.editing) {
                this.update();
            } else {
                this.create();
            }
        },

        update() {
            axios
                .put(
                    route("account.addresses.update", { id: this.form.id }),
                    this.form
                )
                .then(({ data }) => {
                    console.log("dd",data);
                    this.formOpen = false;
                    this.editing = false;

                    this.addresses[this.form.id] = data.address;

                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        create() {
            axios
                .post(route("account.addresses.store"), this.form)
                .then(({ data }) => {
                    // console.log("data",data);
                    this.formOpen = false;

                    let address = { [data.address.id]: data.address };

                    this.$set(this, "addresses", {
                        ...this.addresses,
                        ...address,
                    });

                    this.resetForm();
                    this.$notify(data.message);
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.errors.record(response.data.errors);
                    }

                    this.$notify(response.data.message);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        resetForm() {
            this.form = { state: "" };
        },
    },
};
